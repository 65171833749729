

















import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import {defineComponent, PropType} from '@vue/composition-api';
import {AssistedGradingPhrases} from '@/tasks/models/AssistedGradingMap';

export default defineComponent({
  name: 'AssistiveGradingTest',
  components: {AssistedGradingWritten},
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      inputs: {
        part1: null,
        part2: null,
      },
    };
  },
  computed: {
    queries(): Record<string, AssistedGradingPhrases[]> {
      const gradingMap = this.task?.content?.assistedGradingMap;
      return {
        part1: gradingMap ? gradingMap.part1 : [],
        part2: gradingMap ? gradingMap.part2 : [],
      };
    },
  },
});
